import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { transparentize } from 'polished'

import { Colors } from 'theme/constants'

const MODAL_BACKGROUND_COLOR = transparentize(0.5, Colors.brand.wildBlueYonder)

export const Modal: ComponentMultiStyleConfig = {
  parts: ['overlay', 'footer', 'dialogContainer', 'dialog'],
  baseStyle: {
    size: { base: 'full' },
    overlay: {
      bgColor: MODAL_BACKGROUND_COLOR,
    },
    footer: {
      justifyContent: 'left',
    },
    dialogContainer: {
      minW: { base: '100%', md: 'unset' },
    },
    dialog: {
      /**
       * HACK: there is no way in chakra-ui to either set the `size` prop in relation to breakpoints or
       * extract default styles for a particular component. `Size=full` is achieved in chakra-ui by setting
       * these CSS props the following way:
       */
      minH: { base: '100vh', md: 'unset' },
      minW: { base: '100vw', md: 'unset' },
      mt: { base: '0px', md: '3.75rem' },
      mb: { base: '0px', md: '3.75rem' },
    },
  },
}
