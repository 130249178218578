import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const Textarea: ComponentSingleStyleConfig = {
  variants: {
    outline: {
      px: '2',
      h: '2.5rem',
      borderColor: 'brand.gainsboro',
      borderRadius: 'sm',
      color: 'brand.darkSlateBlue',
      lineHeight: 'shorter',
      fontSize: 'xs',
      fontWeight: 'normal',
      _placeholder: {
        px: '0',
        textTransform: 'none',
        color: 'brand.wildBlueYonder',
        lineHeight: 'shorter',
        fontSize: 'xs',
        fontWeight: 'normal',
      },
      _focus: {
        boxShadow: 'none',
        borderWidth: '1px',
        borderColor: 'brand.darkBlueGray',
        bgColor: 'brand.ghostWhite',
      },
      _invalid: {
        bgColor: 'state.errorHover',
        borderWidth: '1px',
        boxShadow: 'none',
      },
    },
  },
}
