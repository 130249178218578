import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { PropsWithChildrenRequired } from 'constants/typings'

interface LoadingContextType {
  isLoading: boolean
  startLoading: () => void
  finishLoading: () => void
}

const LoadingContext = createContext({} as LoadingContextType)

export const useLoading = (): LoadingContextType => useContext(LoadingContext)

const LoadingProvider = ({ children }: PropsWithChildrenRequired) => {
  const [isLoading, setIsLoading] = useState(false)

  const startLoading = useCallback(() => {
    setIsLoading(true)
  }, [])

  const finishLoading = useCallback(() => {
    setIsLoading(false)
  }, [])

  const contextValue = useMemo<LoadingContextType>(
    () => ({
      isLoading,
      startLoading,
      finishLoading,
    }),
    [finishLoading, isLoading, startLoading]
  )

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider
