import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const Switch: ComponentMultiStyleConfig = {
  parts: ['container', 'track', 'thumb'],
  baseStyle: {
    track: {
      cursor: 'pointer',
      bg: 'brand.wildBlueYonder',
      color: 'blue',
      _checked: {
        bg: 'brand.orange',
      },
      _focus: {
        boxShadow: 'none',
      },
    },
    thumb: {
      color: 'red',
      h: '4',
      w: '4',
    },
  },
  sizes: {
    lg: {
      track: {
        w: '35px',
        h: '5',
        px: '1',
      },
      thumb: {
        h: '4',
        w: '4',
        alignSelf: 'center',
      },
    },
  },
}
