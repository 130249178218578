import { stringify } from 'querystring'

import { isNil, omitBy } from 'lodash'
import { NextRouter } from 'next/router'

import { ReturnToSearchParam, Route } from 'constants/routes'

export const withSearchParams = (searchParams = {}): string => {
  const paramsString = stringify(omitBy(searchParams, isNil))
  if (!paramsString) {
    return ''
  }
  return `?${paramsString}`
}

export const getReturnToSearchParam = (
  router: NextRouter
): ReturnToSearchParam => {
  const { returnTo } = router.query
  if (returnTo) {
    return { returnTo: String(returnTo) }
  }
  const isAtAuthPage = [
    Route.SignIn(),
    Route.SignUp(),
    Route.ForgotPassword(),
  ].includes(router.pathname)
  return isAtAuthPage ? {} : { returnTo: location.href }
}
