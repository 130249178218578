export const SAVED_SELECTED_VENUE_ID_KEY = 'SAVED_SELECTED_VENUE_ID'

type SavedVenue = {
  userId: number
  venueId: number
}

export const getSavedSelectedVenueId = ({ userId }: { userId: number }) => {
  const savedVenue = localStorage.getItem(SAVED_SELECTED_VENUE_ID_KEY)
  if (savedVenue === null) {
    return null
  }
  const parsedSavedVenues = JSON.parse(savedVenue) as SavedVenue
  return parsedSavedVenues.userId === userId ? parsedSavedVenues.venueId : null
}

export const setSavedSelectedVenueId = ({ userId, venueId }: SavedVenue) => {
  localStorage.setItem(
    SAVED_SELECTED_VENUE_ID_KEY,
    JSON.stringify({
      userId,
      venueId,
    })
  )
}

export const removeSavedSelectedVenueId = () => {
  localStorage.removeItem(SAVED_SELECTED_VENUE_ID_KEY)
}
