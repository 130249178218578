import { APP_VERSION_HEADER } from 'constants/app'
import { StepperType } from 'constants/localStorage'
import { TenderFilterOptionsInput } from 'generated/generated-graphql'
import { ReviewAndSubmitStepForm } from 'routes/Bid/Steps/ReviewAndSubmitStep'
import { SupportStepForm } from 'routes/Bid/Steps/SupportStep/SupportForm'
import { OverviewStepForm } from 'routes/Tender/Steps/OverviewStep'
import { ProductCategoryStepForm } from 'routes/Tender/Steps/ProductCategoryStep'
import { ProductListingStepForm } from 'routes/Tender/Steps/ProductListingStep'
import { SupportStructureStepForm } from 'routes/Tender/Steps/SupportStructureStep'
import { ProductAndVolumeStepForm } from 'routes/Tender/Steps/VolumeStep'

import { ChooseProductStepForm } from './Steps/ProductStep/ChooseProductStep'
import { ProductStepForm } from './Steps/ProductStep/types'

const MULTIPLE_BIDS_ADDITIONAL_STORAGE_DATA_KEY =
  'MULTIPLE_BIDS_ADDITIONAL_STORAGE_DATA'

export type BidStepperFormStorage = Partial<{
  0: ChooseProductStepForm
  1: ProductStepForm
  2: SupportStepForm
  3: ReviewAndSubmitStepForm
}>

export type MultipleBidStepperFormStorage = BidStepperFormStorage

export type MultipleBidAdditionalStorageData = {
  excludedTenderIds: string[]
  filterValues: TenderFilterOptionsInput
}

export type TenderStepperFormStorage = Partial<{
  0: ProductCategoryStepForm
  1: ProductListingStepForm
  2: OverviewStepForm
  3: ProductAndVolumeStepForm
  4: SupportStructureStepForm
}>

type GetterForStepperReturnType = () =>
  | (
      | TenderStepperFormStorage
      | BidStepperFormStorage
      | MultipleBidStepperFormStorage
    )
  | undefined

export const getBidStepperFromStorage = () => {
  if (typeof window === 'undefined') {
    return undefined
  }
  const appVersion = localStorage.getItem(APP_VERSION_HEADER) || 'version'
  const bid = localStorage.getItem(`${StepperType.Bid}-${appVersion}`)
  return bid ? (JSON.parse(bid) as BidStepperFormStorage) : undefined
}

export const getMultipleBidStepperFromStorage = () => {
  if (typeof window === 'undefined') {
    return undefined
  }
  const appVersion = localStorage.getItem(APP_VERSION_HEADER) || 'version'
  const bid = localStorage.getItem(`${StepperType.MultipleBid}-${appVersion}`)
  return bid ? (JSON.parse(bid) as BidStepperFormStorage) : undefined
}

export const getTenderStepperFromStorage = () => {
  if (typeof window === 'undefined') {
    return undefined
  }
  const appVersion = localStorage.getItem(APP_VERSION_HEADER) || 'version'
  const tender = localStorage.getItem(`${StepperType.Tender}-${appVersion}`)
  // TODO: remove this disable when doing tender stepper
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unnecessary-type-assertion
  return tender ? (JSON.parse(tender) as TenderStepperFormStorage) : undefined
}

export const getMultipleBidAdditionalStorageData = () => {
  if (typeof window === 'undefined') {
    return undefined
  }
  const appVersion = localStorage.getItem(APP_VERSION_HEADER) || 'version'
  const storageData = localStorage.getItem(
    `${MULTIPLE_BIDS_ADDITIONAL_STORAGE_DATA_KEY}-${appVersion}`
  )
  return storageData
    ? (JSON.parse(storageData) as MultipleBidAdditionalStorageData)
    : undefined
}

export const addMultipleBidAdditionalStorageData = (
  data: MultipleBidAdditionalStorageData
) => {
  if (typeof window === 'undefined') {
    return
  }
  const appVersion = localStorage.getItem(APP_VERSION_HEADER) || 'version'
  localStorage.setItem(
    `${MULTIPLE_BIDS_ADDITIONAL_STORAGE_DATA_KEY}-${appVersion}`,
    JSON.stringify(data)
  )
}

export const removeBidStepperFromStorage = () => {
  if (typeof window === 'undefined') {
    return
  }
  const appVersion = localStorage.getItem(APP_VERSION_HEADER) || 'version'
  localStorage.removeItem(`${StepperType.Bid}-${appVersion}`)
}

export const removeMultipleBidStepperFromStorage = () => {
  if (typeof window === 'undefined') {
    return
  }
  const appVersion = localStorage.getItem(APP_VERSION_HEADER) || 'version'
  localStorage.removeItem(`${StepperType.MultipleBid}-${appVersion}`)
}

export const removeTenderStepperFromStorage = () => {
  if (typeof window === 'undefined') {
    return
  }
  const appVersion = localStorage.getItem(APP_VERSION_HEADER) || 'version'
  localStorage.removeItem(`${StepperType.Tender}-${appVersion}`)
}

export const LocalStorageGetterMapperForStepper: Record<
  StepperType,
  GetterForStepperReturnType
> = {
  [StepperType.Tender]: getTenderStepperFromStorage,
  [StepperType.Bid]: getBidStepperFromStorage,
  [StepperType.MultipleBid]: getMultipleBidStepperFromStorage,
}
