import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const Text: ComponentSingleStyleConfig = {
  variants: {
    error: {
      fontSize: 'xs',
      lineHeight: 'none',
      fontWeight: 'normal',
      fontFamily: 'Proxima Nova',
      color: 'state.error',
      ml: '1',
      my: '0',
    },
    warning: {
      fontSize: 'xs',
      lineHeight: 'none',
      fontWeight: 'normal',
      fontFamily: 'Proxima Nova SemiBold',
      color: 'state.warning',
      ml: '1',
      my: '0',
    },
    bottomLabel: {
      fontSize: 'xs',
      lineHeight: 'none',
      fontWeight: 'normal',
      fontFamily: 'Proxima Nova',
      color: 'brand.wildBlueYonder',
      ml: '1',
      my: '0',
    },
    link: {
      cursor: 'pointer',
      _hover: {
        textDecoration: 'underline',
      },
    },
  },
}
