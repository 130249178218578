import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { uniq } from 'lodash'

import { PropsWithChildrenRequired } from 'constants/typings'
import { useChooseMultipleTenderWinnersMutation } from 'generated/generated-graphql'
import { useSuccessToast, useErrorToast } from 'utils/toast'

interface ChooseMultipleWinnersContextType {
  selectedBidIds: number[]
  onSelectBid: (id: number) => void
  onSelectMultipleBids: (ids: number[]) => void
  onUnselectBid: (id: number) => void
  onUnselectAllBids: () => void
  chooseWinners: () => Promise<void>
  isAvailable: boolean
  isLoadingChooseMultipleTenderWinners: boolean
  setIsAvailable: Dispatch<SetStateAction<boolean>>
  isShowSelectedOnlyFilterActive: boolean
  setIsShowSelectedOnlyFilterActive: Dispatch<SetStateAction<boolean>>
  reset: () => void
}

const ChooseMultipleWinnersContext = createContext(
  {} as ChooseMultipleWinnersContextType
)

export const useChooseMultipleWinners = (): ChooseMultipleWinnersContextType =>
  useContext(ChooseMultipleWinnersContext)

const ChooseMultipleWinnersProvider = ({
  children,
}: PropsWithChildrenRequired) => {
  const [selectedBidIds, setSelectedBidIds] = useState<number[]>([])
  const [isAvailable, setIsAvailable] = useState(true)
  const [isShowSelectedOnlyFilterActive, setIsShowSelectedOnlyFilterActive] =
    useState(false)

  const showSuccessToast = useSuccessToast()
  const showErrorToast = useErrorToast()

  const [
    chooseMultipleTenderWinners,
    { loading: isLoadingChooseMultipleTenderWinners },
  ] = useChooseMultipleTenderWinnersMutation()

  const reset = useCallback(() => {
    setSelectedBidIds([])
    setIsAvailable(false)
    setIsShowSelectedOnlyFilterActive(false)
  }, [])

  const chooseWinners = useCallback(async () => {
    try {
      await chooseMultipleTenderWinners({
        variables: {
          input: {
            bidIds: selectedBidIds,
          },
        },
        refetchQueries: ['BrandDashboardBids', 'Bids', 'bidsViewFilterOptions'],
      })

      // TODO: add translations
      showSuccessToast({
        description: 'Winning tender bids have been succesfully chosen',
        title: 'Winning bids chosen',
      })
    } catch (error) {
      // TODO: add translations
      showErrorToast({
        description: 'Failed to choose winning bids',
        title: 'Error',
      })
    }
  }, [
    chooseMultipleTenderWinners,
    selectedBidIds,
    showErrorToast,
    showSuccessToast,
  ])

  const onSelectBid = (id: number) => {
    setSelectedBidIds((prevState) => [...prevState, id])
  }

  const onSelectMultipleBids = (ids: number[]) => {
    setSelectedBidIds((prevState) => uniq([...prevState, ...ids]))
  }

  const onUnselectBid = (id: number) => {
    setSelectedBidIds((prevState) => prevState.filter((bidId) => bidId !== id))
  }

  const onUnselectAllBids = () => {
    setSelectedBidIds([])
  }

  // Reset selected filters state when selected bids are empty
  useEffect(() => {
    if (selectedBidIds.length === 0 && isShowSelectedOnlyFilterActive) {
      setIsShowSelectedOnlyFilterActive(false)
    }
  }, [isShowSelectedOnlyFilterActive, selectedBidIds.length])

  const contextValue = useMemo<ChooseMultipleWinnersContextType>(
    () => ({
      selectedBidIds,
      onSelectBid,
      onSelectMultipleBids,
      onUnselectBid,
      onUnselectAllBids,
      chooseWinners,
      isAvailable,
      setIsAvailable,
      isShowSelectedOnlyFilterActive,
      setIsShowSelectedOnlyFilterActive,
      reset,
      isLoadingChooseMultipleTenderWinners,
    }),
    [
      selectedBidIds,
      chooseWinners,
      isAvailable,
      isShowSelectedOnlyFilterActive,
      reset,
      isLoadingChooseMultipleTenderWinners,
    ]
  )
  return (
    <ChooseMultipleWinnersContext.Provider value={contextValue}>
      {children}
    </ChooseMultipleWinnersContext.Provider>
  )
}

export default ChooseMultipleWinnersProvider
