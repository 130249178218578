import validator from 'validator'

import { HTTP_PROTOCOL, HTTPS_PROTOCOL } from 'constants/url'

export const getUrlWithoutProtocol = (url: string) =>
  url.replace(HTTPS_PROTOCOL, '')

export const getUrlWithProtocol = (url: string) => {
  if (url.substring(0, 8) === HTTPS_PROTOCOL) {
    return url
  }
  if (url.substring(0, 7) === HTTP_PROTOCOL) {
    return url
  }
  return `${HTTPS_PROTOCOL}${url}`
}

export const validateUrl = ({ url }: { url: string }) => {
  const polishedUrl = url.trim().replace(/\/+$/, '')
  const dotLastIndex = polishedUrl.lastIndexOf('.')

  return Boolean(
    validator.isURL(polishedUrl, {
      protocols: ['http', 'https'],
    }) &&
      dotLastIndex > 0 &&
      dotLastIndex !== polishedUrl.length - 1
  )
}
