import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { PropsWithChildrenRequired } from 'constants/typings'
import { TenderFilterOptionsInput } from 'generated/generated-graphql'
import { getMultipleBidAdditionalStorageData } from 'routes/Bid/storageUtils'

interface MultipleBidsContextType {
  excludedTenderIds: string[]
  setExcludedTenderIds: (tenderIds: string[]) => void
  filterValues: TenderFilterOptionsInput
  setFilterValues: (filterValues: TenderFilterOptionsInput) => void
  resetValues: () => void
}

const MultipleBidsContext = createContext({} as MultipleBidsContextType)

export const useMultipleBids = (): MultipleBidsContextType =>
  useContext(MultipleBidsContext)

const MultipleBidsProvider = ({ children }: PropsWithChildrenRequired) => {
  const [excludedTenderIds, setExcludedTenderIds] = useState<string[]>(
    () => getMultipleBidAdditionalStorageData()?.excludedTenderIds || []
  )
  const [filterValues, setFilterValues] = useState<TenderFilterOptionsInput>(
    () => getMultipleBidAdditionalStorageData()?.filterValues || {}
  )

  const resetValues = useCallback(() => {
    setExcludedTenderIds([])
    setFilterValues({})
  }, [setExcludedTenderIds, setFilterValues])

  const contextValue = useMemo(
    () => ({
      excludedTenderIds,
      filterValues,
      setExcludedTenderIds,
      setFilterValues,
      resetValues,
    }),
    [
      excludedTenderIds,
      filterValues,
      setExcludedTenderIds,
      setFilterValues,
      resetValues,
    ]
  )

  return (
    <MultipleBidsContext.Provider value={contextValue}>
      {children}
    </MultipleBidsContext.Provider>
  )
}

export default MultipleBidsProvider
