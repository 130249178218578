import { Region } from 'api/generated/resolvers-types'

export const I18nRegionLanguageMap = {
  [Region.UnitedKingdom]: 'en',
  [Region.Australia]: 'en-au',
  [Region.Spain]: 'es',
}

export const RegionPriceLocaleMap = {
  [Region.UnitedKingdom]: 'en-GB',
  [Region.Australia]: 'en-AU',
  [Region.Spain]: 'es-ES',
}
