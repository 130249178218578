import { Global } from '@emotion/react'

import { FontFamily, FontUrl } from 'constants/fonts'

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: ${FontFamily.CocogoosePro};
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(${FontUrl['Cocogoose Pro']}) format('opentype'), url(${FontUrl['Cocogoose Pro']}) format('otf');
      }
      @font-face {
        font-family: ${FontFamily.ProximaNova};
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${FontUrl['Proxima Nova']}) format('opentype'), url(${FontUrl['Proxima Nova']}) format('oft');
      }
      @font-face {
        font-family: ${FontFamily.ProximaNovaMedium};
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(${FontUrl['Proxima Nova Medium']}) format('opentype'), url(${FontUrl['Proxima Nova Medium']}) format('oft');
      }
      @font-face {
        font-family: ${FontFamily.ProximaNovaSemibold};
        font-style: normal;
        font-weight: 600 ;
        font-display: swap;
        src: url(${FontUrl['Proxima Nova Semibold']}) format('opentype'), url(${FontUrl['Proxima Nova Semibold']}) format('oft');
      }
      `}
  />
)
