import React, { useEffect } from 'react'

import { useRouter } from 'next/router'

import { PropsWithChildrenRequired } from 'constants/typings'
import { useToast } from 'utils/toast'

/**
 * To add more toast "options", just add the desired toast option to this array
 * and TS will highlight the parts where you should update the values
 */
const PossibleToastParamOptions = [
  'success',
  'info',
  'warning',
  'error',
] as const

type PossibleToastParamOption = (typeof PossibleToastParamOptions)[number]

const GlobalToastMessages: Record<string, string> = {
  EXAMPLE_TOAST: 'This is an example toast to try out!',
  BESPOKE_SUCCESS: 'Your bespoke plan was successfully updated.',
}

type ToastsToPromptShape = {
  type: PossibleToastParamOption
  messageCode: string
  messageText: string
}

export const GlobalToasts = ({ children }: PropsWithChildrenRequired) => {
  const router = useRouter()
  const toast = useToast()

  useEffect(() => {
    const toastsToPrompt = Object.entries(router.query).reduce(
      (acc, [queryKey, queryValue]) => {
        if (
          PossibleToastParamOptions.includes(
            queryKey as PossibleToastParamOption
          ) &&
          typeof queryValue === 'string'
        ) {
          const messageToShow = GlobalToastMessages[queryValue]
          if (!messageToShow || messageToShow.length === 0) {
            return acc
          }
          return [
            ...acc,
            {
              type: queryKey as PossibleToastParamOption,
              messageCode: queryValue,
              messageText: messageToShow,
            },
          ]
        }
        return acc
      },
      [] as ToastsToPromptShape[]
    )
    toastsToPrompt.forEach(({ type, messageCode }) => {
      toast({
        status: type,
        description: GlobalToastMessages[messageCode],
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  // this line is disabled in case children is more than one component
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
