import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const FormLabel: ComponentSingleStyleConfig = {
  variants: {
    input: {
      fontFamily: 'Proxima Nova',
      fontSize: 'xs',
      lineHeight: 'shorter',
      fontWeight: 'normal',
      ml: '1',
      my: '0',
    },
    switch: {
      cursor: 'pointer',
      pl: '2',
      pb: '0.5',
      lineHeight: 'shorter',
      fontSize: '13px',
      fontWeight: 'normal',
      fontFamily: 'Proxima Nova',
    },
  },
}
