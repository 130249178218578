import { transparentize } from 'polished'

export const DeviceBreakpoints = {
  xs: '320px',
  sm: '480px',
  md: '768px',
  lg: '992px',
  xl: '1296px',
  '2xl': '1536px',
}

export interface ThemeColors {
  brand: {
    secondary: {
      ultramarine900: string
      ultramarine800: string
      ultramarine500: string
      ultramarine100: string
      ultramarine50: string
      tangerine50: string
    }
    primary: {
      ultramarine: string
    }
    ultramarine: string
    ultramarine50: string
    orange: string
    aliceBlue: string
    americanBlue: string
    darkSlateBlue: string
    purpleNavy: string
    lightBlue: string
    darkBlueGray: string
    wildBlueYonder: string
    nouveau: string
    gainsboro: string
    mistyGray: string
    brightGray: string
    fogGray: string
    ghostWhite: string
    darkGray: string
    black: string
    white: string
  }
  state: {
    success: string
    warning: string
    error: string
    lightError: string
    info: string
    hover: string
    hoverLight: string
    errorHover: string
    errorLight: string
    orangeHover: string
    orangeActive: string
    orangeDisabled: string
    incompatibleTagHover: string
    compatibleTagHover: string
  }
}

export const Colors: ThemeColors = {
  brand: {
    secondary: {
      // TODO: Add New Theme colors based on new Figma design
      ultramarine900: '#393E75',
      ultramarine800: '#374697',
      ultramarine500: '#315CFD',
      ultramarine100: '#CFD9FF',
      ultramarine50: '#F6F8FF',
      tangerine50: '#FFFAEC',
    },
    primary: {
      // TODO: Add New Theme colors based on new Figma design
      ultramarine: '#315CFD',
    },
    ultramarine: '#315CFD',
    ultramarine50: '#F6F8FF',
    orange: '#FF8400',
    aliceBlue: '#EAEFFF',
    americanBlue: '#393E75',
    darkSlateBlue: '#404882',
    purpleNavy: '#49518E',
    lightBlue: '#E0E7FF',
    darkBlueGray: '#6872A5',
    wildBlueYonder: '#A4ACCA',

    nouveau: '#6B7280',
    gainsboro: '#D8DBE7',
    mistyGray: '#D9E2EF',
    brightGray: '#E9EBF2',
    fogGray: '#F3F6F9',
    ghostWhite: '#F7F8FB',
    darkGray: '#595959',
    black: '#161C2D',
    white: '#FFFFFF',
  },
  state: {
    success: '#76CA66',
    warning: '#FBC756',
    error: '#BA0000',
    lightError: '#FFA5A5',
    info: '#A0C3FF',
    hover: '#798BFF',
    hoverLight: '#EAEBFF',
    errorHover: '#F4D5D5',
    errorLight: '#FEFAFE',
    orangeHover: '#FFA749',
    orangeActive: '#C26602',
    orangeDisabled: '#ffdbb3',
    incompatibleTagHover: '#EFAB16',
    compatibleTagHover: '#3FBB27',
  },
}

export const TEXT_COLOR = Colors.brand.americanBlue
export const BASE_FONT_SIZE_PX = '18px'
export const HEADER_HEIGHT_PX = '80px'
export const HEADER_HEIGHT = 80
export const WarningTopBarHeight = {
  Base: 75,
  Md: 50,
}
export const ORANGE_NOTIFICATION_BG_COLOR = transparentize(
  0.92,
  Colors.brand.orange
)
export const GRAY_NOTIFICATION_BG_COLOR = transparentize(
  0.92,
  Colors.brand.americanBlue
)

export const SWITCH_WIDTH = 38
