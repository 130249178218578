import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { transparentize } from 'polished'

import { Colors } from '../constants'

export const Popover: ComponentMultiStyleConfig = {
  parts: ['content'],
  baseStyle: {
    content: {
      border: 'none',
      boxShadow: `0px 0px 15px 6px ${transparentize(0.6, Colors.brand.black)}`,
      ':focus': {
        boxShadow: `0px 0px 15px 6px ${transparentize(
          0.6,
          Colors.brand.black
        )}`,
      },
    },
  },
  variants: {
    smallShadow: {
      content: {
        border: 'none',
        boxShadow: `0px 15px 25px 0px ${transparentize(
          0.85,
          Colors.brand.black
        )}`,
        ':focus': {
          boxShadow: `0px 15px 25px 0px ${transparentize(
            0.85,
            Colors.brand.black
          )}`,
        },
      },
    },
    lightShadow: {
      content: {
        border: 'none',
        boxShadow: `0px 15px 25px  ${transparentize(0.8, Colors.brand.black)}`,
        ':focus': {
          boxShadow: `0px 15px 25px  ${transparentize(
            0.8,
            Colors.brand.black
          )}`,
        },
      },
    },
    regionSelect: {
      content: {
        border: 'none',
        boxShadow: `2px 2px 20px 0px ${transparentize(
          0.88,
          Colors.brand.black
        )}`,
        ':focus': {
          boxShadow: `2px 2px 20px 0px ${transparentize(
            0.88,
            Colors.brand.black
          )}`,
        },
      },
    },
    support: {
      content: {
        border: 'none',
        boxShadow: `0px -2px 25px  ${transparentize(
          0.8,
          Colors.brand.americanBlue
        )}`,
        ':focus': {
          boxShadow: `0px -2px 25px  ${transparentize(
            0.8,
            Colors.brand.americanBlue
          )}`,
        },
      },
    },
    tooltip: {
      content: {
        minHeight: '43px',
        fontSize: '13px',
        bg: 'brand.black',
        color: 'brand.white',
        border: 'none',
        boxShadow: `0px 4px 10px ${transparentize(0.97, Colors.brand.black)}`,
        ':focus': {
          boxShadow: `0px 4px 10px ${transparentize(0.97, Colors.brand.black)}`,
        },
      },
    },
  },
}
