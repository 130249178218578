export const ErrorMessage = {
  Required: 'TRANSLATION_107',
  PasswordMinLength: 'TRANSLATION_108',
  PasswordsDontMatch: 'TRANSLATION_109',
  EmailWrongFormat: 'TRANSLATION_110',
  EmailAlreadyExists: 'TRANSLATION_111',
  AddressNotSufficient: 'TRANSLATION_112',
  RequiredConsent: 'TRANSLATION_113',
  CompanyNumberMinLength: 'TRANSLATION_114',
  CompanyNumberNotFound: 'Company data was not found',
  VatNumberNotFound: 'TRANSLATION_116',
  VatNumberLength: 'TRANSLATION_117',
  AwrsNumberMinLength: 'TRANSLATION_118',
  AwrsNumberNotFound: 'TRANSLATION_119',
  WholesalerMinLength: 'TRANSLATION_120',
  BespokeAuthError: 'TRANSLATION_121',
  IncorrectTokenFormat: 'TRANSLATION_122',
  Subscription: 'TRANSLATION_123',
}
