import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const Select: ComponentMultiStyleConfig = {
  parts: ['field', 'icon'],
  variants: {
    outline: {
      field: {
        px: '2',
        h: '2.5rem',
        borderColor: 'brand.gainsboro',
        borderRadius: 'sm',
        color: 'brand.darkSlateBlue',
        lineHeight: 'shorter',
        fontSize: { base: '16px', lg: 'xs' },
        fontWeight: 'normal',
        _focus: {
          boxShadow: 'none',
          borderColor: 'brand.gainsboro',
        },
      },
      icon: {
        color: 'brand.darkBlueGray',
      },
    },
  },
}
