import { IncomingMessage, ServerResponse } from 'http'

import { useMemo } from 'react'

import {
  ApolloClient,
  FieldPolicy,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { Reference } from '@apollo/client/utilities'
import { CreateToastFnReturn } from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
import { flatten } from 'flat'
import { merge } from 'lodash'

import { APP_VERSION_HEADER } from 'constants/app'
import { ErrorMessage } from 'constants/messages'
import { FirebaseApp } from 'utils/firebase'
import { SharedToastOptions } from 'utils/toast'

let globalApolloClient: ApolloClient<NormalizedCacheObject> | undefined

export type ResolverContext = {
  req?: IncomingMessage
  res?: ServerResponse
}

// Using  Next.js example
// Eslint disabled to keep custom Next.js bundling functionality
function createIsomorphLink(
  context: ResolverContext = {},
  toast?: CreateToastFnReturn
) {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { Schema: schema } = require('api/utils/schema')
    // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/naming-convention
    const { SchemaLink } = require('@apollo/client/link/schema')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return new SchemaLink({ schema, context })
    // eslint-disable-next-line no-else-return
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/naming-convention
    const { HttpLink, ApolloLink } = require('@apollo/client')
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { setContext } = require('@apollo/client/link/context')
    const firebaseAuth = getAuth(FirebaseApp)

    // @ts-ignore
    const authLink = setContext(async (_, ctx) => {
      const { headers } = ctx
      try {
        const firstCurrentUser = firebaseAuth.currentUser
        const token = await firstCurrentUser?.getIdToken()
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        }
      } catch {
        return {
          headers,
        }
      }
    })
    const afterwareLink = new ApolloLink(
      (operation: Operation, forward: NextLink) => {
        const clientAppVersion = localStorage.getItem(APP_VERSION_HEADER)
        return forward(operation).map((response) => {
          const innerContext = operation.getContext()
          const versionHeader =
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            innerContext?.response?.headers?.get(APP_VERSION_HEADER)
          if (clientAppVersion === null) {
            // user is visiting the page for the first time
            localStorage.setItem(APP_VERSION_HEADER, String(versionHeader))
          } else if (clientAppVersion !== versionHeader) {
            localStorage.setItem(APP_VERSION_HEADER, String(versionHeader))
            alert(
              'There is a new EzTenda app version available. Click OK to refresh.'
            )
            location.reload()
          }
          return response
        })
      }
    )
    const httpLink = new HttpLink({
      uri: '/api/graphql',
      credentials: 'same-origin',
    })
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (networkError) {
        toast?.({
          ...SharedToastOptions,
          description: networkError.message,
          status: 'error',
        })
      }
      if (graphQLErrors) {
        graphQLErrors.forEach((graphQLError) => {
          if (graphQLError.message !== ErrorMessage.CompanyNumberNotFound) {
            toast?.({
              ...SharedToastOptions,
              description: graphQLError.message,
              status: 'error',
            })
          }
        })
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return ApolloLink.from([authLink, afterwareLink, errorLink, httpLink])
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/default-param-last,@typescript-eslint/no-unsafe-argument */
// @ts-ignore
const parseInputDefault = (input) => {
  const {
    args: {
      // @ts-ignore
      input: { pagination: { limit, offset } = {}, ...inputRest } = {},
    } = {},
  } = input
  return {
    inputRest,
    limit,
    offset,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KeyArgs = FieldPolicy<any>['keyArgs']

export const createCacheKey = (input = {}) => {
  const flattenInput = flatten(input)
  return JSON.stringify(
    // @ts-ignore
    Object.keys(flattenInput)
      .sort()
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      .map((key) => `${key}:${flattenInput[key]}`)
  )
}

export const customOffsetLimitPagination = <T = Reference>(
  { parseInput } = {
    parseInput: parseInputDefault,
  },
  keyArgs: KeyArgs = false
): FieldPolicy<T> => ({
  keyArgs,
  // @ts-ignore
  read(existing = {}, input) {
    const { inputRest } = parseInput(input)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const cacheKey = createCacheKey(inputRest)
    // @ts-ignore
    const itemFromCache = existing[cacheKey]
    if (!itemFromCache) {
      return undefined
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return itemFromCache
  },
  // @ts-ignore
  merge(existing = {}, incoming, input) {
    const { offset, inputRest } = parseInput(input)
    const cacheKey = createCacheKey(inputRest)
    // @ts-ignore
    const itemFromCache = existing[cacheKey]
    if (!itemFromCache) {
      return {
        ...existing,
        [cacheKey]: incoming,
      }
    }
    const merged = {
      ...existing,
      [cacheKey]: {
        ...itemFromCache,
        ...incoming,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        data: [...itemFromCache.data.slice(0)],
      },
    }

    // @ts-ignore
    const newEnd = Number(offset) + Number(incoming.data.length)
    const oldEnd = itemFromCache?.data?.length ?? 0
    // eslint-disable-next-line  no-plusplus
    for (let i = Math.min(offset, oldEnd); i < newEnd; ++i) {
      if (i >= offset) {
        // @ts-ignore
        merged[cacheKey].data[i] = incoming.data[i - offset]
      } else if (i >= oldEnd) {
        merged[cacheKey].data[i] = null
      }
    }
    return merged
    /* eslint-enable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/default-param-last,@typescript-eslint/no-unsafe-argument */
  },
})

function createApolloClient(
  context?: ResolverContext,
  toast?: CreateToastFnReturn
) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createIsomorphLink(context, toast),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            bids: customOffsetLimitPagination(),
            venueDashboardTenders: customOffsetLimitPagination(),
            tenderList: customOffsetLimitPagination(),
          },
        },
      },
    }),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })
}

export function initializeApollo(
  {
    initialState,
    toast,
  }: {
    initialState?: NormalizedCacheObject
    toast?: CreateToastFnReturn
  } = {},
  context?: ResolverContext
) {
  const apolloClient = globalApolloClient ?? createApolloClient(context, toast)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    let mergedCache = initialState

    if (globalApolloClient) {
      // Get existing cache, loaded during client side data fetching
      const existingCache = apolloClient.extract()

      // Merge the existing cache into data passed from getStaticProps/getServerSideProps
      mergedCache = merge({}, initialState, existingCache)
    }

    apolloClient.cache.restore(mergedCache)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return apolloClient
  }
  // Create the Apollo Client once in the client
  if (!globalApolloClient) {
    globalApolloClient = apolloClient
  }

  return apolloClient
}

export function useApollo(
  initialState?: NormalizedCacheObject,
  toast?: CreateToastFnReturn
) {
  const store = useMemo(
    () => initializeApollo({ initialState, toast }),
    [initialState, toast]
  )
  return store
}
