import { ComponentSingleStyleConfig } from '@chakra-ui/react'
import { transparentize } from 'polished'

import { Colors } from 'theme/constants'

export const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    _focus: {
      boxShadow: 'none',
    },
    fontFamily: 'Proxima Nova',
    borderRadius: 'md',
  },
  variants: {
    link: {
      color: 'brand.orange',
      minWidth: 'fit-content',
      fontWeight: 'normal',
      fontSize: '14px',
      textDecoration: 'underline',
      _disabled: {
        opacity: 0.4,
      },
      _active: {
        color: 'brand.purpleNavy',
      },
    },
    primary: {
      h: '44px',
      bgColor: 'brand.ultramarine',
      color: 'white',
      fontSize: 'sm',
      lineHeight: 'shorter',
      fontWeight: 'semibold',
      px: '5',
      _active: {
        bgColor: 'brand.americanBlue',
      },
      _hover: {
        bgColor: 'state.hover',
        _disabled: {
          bgColor: 'brand.ghostWhite',
        },
      },
      _disabled: {
        color: 'brand.wildBlueYonder',
        bgColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
    outline: {
      color: 'brand.ultramarine',
      bgColor: 'white',
      borderColor: 'brand.ultramarine',
      fontSize: 'sm',
      h: '44px',
      lineHeight: 'shorter',
      fontWeight: 'semibold',
      px: '5',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: 'white',
        bgColor: 'brand.americanBlue',
        borderColor: 'brand.americanBlue',
      },
      _hover: {
        bgColor: 'state.hoverLight',
      },
      _disabled: {
        color: 'brand.wildBlueYonder',
        bgColor: 'brand.ghostWhite',
        borderColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
    secondary: {
      color: 'brand.purpleNavy',
      bgColor: 'brand.brightGray',
      fontSize: 'sm',
      lineHeight: 'shorter',
      h: '44px',
      fontWeight: 'semibold',
      px: '5',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: 'brand.wildBlueYonder',
        bgColor: 'brand.darkBlueGray',
      },
      _hover: {
        bgColor: 'brand.gainsboro',
      },
      _disabled: {
        color: 'brand.wildBlueYonder',
        bgColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
    secondaryOutline: {
      color: 'brand.purpleNavy',
      bgColor: 'white',
      borderColor: 'brand.purpleNavy',
      borderWidth: '1px',
      fontSize: 'sm',
      h: '44px',
      lineHeight: 'shorter',
      fontWeight: 'semibold',
      px: '5',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: 'brand.purpleNavy',
        bgColor: 'brand.gainsboro',
        borderColor: 'brand.purpleNavy',
      },
      _hover: {
        bgColor: 'brand.ghostWhite',
      },
      _disabled: {
        color: 'brand.wildBlueYonder',
        bgColor: 'brand.ghostWhite',
        borderColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
    secondaryOutlineNoHover: {
      color: 'brand.purpleNavy',
      bgColor: 'white',
      borderColor: 'brand.purpleNavy',
      borderWidth: '1px',
      fontSize: 'sm',
      h: '44px',
      lineHeight: 'shorter',
      fontWeight: 'semibold',
      px: '5',
      cursor: 'default',
      _focus: {
        boxShadow: 'none',
      },
    },
    orange: {
      h: '44px',
      bgColor: 'brand.orange',
      color: 'white',
      fontSize: 'sm',
      lineHeight: 'shorter',
      fontWeight: 'semibold',
      px: '5',
      _active: {
        bgColor: 'state.orangeActive',
      },
      _hover: {
        bgColor: 'state.orangeHover',
        _disabled: {
          bgColor: 'state.orangeDisabled',
        },
      },
      _disabled: {
        color: 'white',
        bgColor: 'state.orangeDisabled',
        opacity: '1',
      },
    },
    lightBlue: {
      h: '44px',
      bgColor: 'brand.lightBlue',
      color: 'brand.ultramarine',
      fontSize: 'sm',
      lineHeight: 'shorter',
      fontWeight: 'semibold',
      px: '5',
      _hover: {
        bgColor: transparentize(0.65, Colors.brand.ultramarine),
        _disabled: {
          bgColor: 'brand.ghostWhite',
        },
      },
      _active: {
        bgColor: transparentize(0.2, Colors.brand.ultramarine),
        color: 'white',
      },
      _disabled: {
        color: 'white',
        bgColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
    dropdownItem: {
      color: 'brand.purpleNavy',
      fontSize: '12px',
      fontWeight: 'normal',
      _hover: {
        color: 'brand.ultramarine',
        backgroundColor: 'none',
      },
      _focus: {
        color: 'brand.ultramarine',
        backgroundColor: 'none',
      },
      _active: {
        color: 'brand.ultramarine',
        backgroundColor: 'none',
      },
    },
    filter: {
      color: 'brand.purpleNavy',
      bgColor: 'brand.brightGray',
      fontSize: 'body',
      lineHeight: 'shorter',
      h: '32px',
      fontWeight: 'normal',
      px: '2',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: 'brand.wildBlueYonder',
        bgColor: 'brand.darkBlueGray',
      },
      _hover: {
        bgColor: 'brand.gainsboro',
      },
      _disabled: {
        color: 'brand.wildBlueYonder',
        bgColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
    activeFilter: {
      color: 'brand.ultramarine',
      bgColor: 'white',
      borderColor: 'brand.ultramarine',
      borderWidth: '1px',
      fontSize: 'body',
      lineHeight: 'shorter',
      h: '32px',
      fontWeight: 'normal',
      px: '2',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: 'white',
        bgColor: 'brand.americanBlue',
        borderColor: 'brand.americanBlue',
      },
      _hover: {
        bgColor: 'state.hoverLight',
      },
      _disabled: {
        color: 'brand.wildBlueYonder',
        bgColor: 'brand.ghostWhite',
        borderColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
    tableHead: {
      textTransform: 'uppercase',
      color: 'brand.darkSlateBlue',
      bgColor: 'none',
      fontSize: '13px',
      _focus: {
        boxShadow: 'none',
      },
    },
    pagination: {
      color: 'brand.darkBlueGray',
      width: '20px',
      height: '40px',
      bgColor: 'none',
      fontSize: '14px',
      fontsize: 'Proxima Nova Light',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        color: 'brand.ultramarine',
        bgColor: 'brand.ghostWhite',
      },
    },
    paginationActive: {
      color: 'brand.ultramarine',
      width: '20px',
      height: '40px',
      bgColor: 'brand.ghostWhite',
      fontSize: '14px',
      fontsize: 'Proxima Nova Light',
      _focus: {
        boxShadow: 'none',
      },
    },
    ghost: {
      _hover: {
        bgColor: 'transparent',
      },
      _active: {
        bgColor: 'transparent',
      },
    },
    selectableFilter: {
      color: 'brand.americanBlue',
      bgColor: 'brand.white',
      borderColor: 'brand.orange',
      borderWidth: '1px',
      borderRadius: '3px',
      fontSize: 'sm',
      lineHeight: 'shorter',
      h: '32px',
      fontWeight: 'normal',
      px: '12px',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        bgColor: 'brand.orange',
      },
      _hover: {
        bgColor: 'brand.orange',
      },
      _disabled: {
        color: 'brand.wildBlueYonder',
        bgColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
}
