import { ApolloProvider } from '@apollo/client'
import { useToast } from '@chakra-ui/react'

import { PropsWithChildrenRequired } from 'constants/typings'
import { useApollo } from 'utils/apolloClient'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageProps: any
}

const CustomApolloProvider = ({
  children,
  pageProps,
}: PropsWithChildrenRequired<Props>) => {
  const toast = useToast()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const apolloClient = useApollo(pageProps.initialApolloState, toast)
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default CustomApolloProvider
